import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './pages/Login/Login';

import './App.css';
import DefaultLayout from './component/Default/Default';


function App() {

  const [submitted, setSubmitted] = useState(() => {
    // Retrieve the submitted state from sessionStorage or default to false
    const savedState = sessionStorage.getItem('submitted');
    return savedState === 'true'; // Convert string back to boolean
  });

  // Persist the submitted state whenever it changes
  useEffect(() => {
    sessionStorage.setItem('submitted', submitted);
  }, [submitted]);

  return (
    <Router>
      <div className="App">
        <Routes>
          {submitted ?
            <Route path="/*" element={<DefaultLayout />}
            /> :
            <Route path='/' element={<Login

              submitted={submitted}
              setSubmitted={setSubmitted}

            />} />}
          {/* <Route path="/signup" element={<Signup />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/for-verify" element={<ForpassOTP />} />
          <Route path="/password-update" element={<UpdatePassword />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
