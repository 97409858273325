import React, { useEffect, useState } from "react";
import './Dashboard.css';
import Assets from "../../Assets/Assets"; // Assuming you have some assets
import moment from "moment";

function Dashboard() {
    const [leadsDetails, setLeadsDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const fetchLeadDetails = async () => {
        console.log('llllllllllllllllllll')
        const token = sessionStorage.getItem("Token");
        console.log(token)
        if (!token) {
            setError("No authentication token found!");
            setLoading(false);
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authtoken", token);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                "https://api.theleadsense.com/api/v1/count/details",
                requestOptions
            );
            const result = await response.json();

            if (result?.data?.counts) {
                // Assuming counts is present in the response
                setLeadsDetails(result.data.counts); // Set the counts data
            } else {
                setError("No lead data found.");
            }
        } catch (err) {
            console.error("Error:", err);
            setError("Error fetching lead data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeadDetails();
    }, []);
    const user_name = sessionStorage.getItem('user_name')
    // Extract counts from leadsDetails state
    const { totalLeads, openLead, rnr, progress, prospect, goodLead } = leadsDetails;
    const currentDate = moment().format("MMMM D, YYYY");
    return (
        <>
            <div className="dashboard">
                <div className="card afff" style={{ width: "100%", maxWidth: "none" }}>
                    <div className="" style={{ marginLeft: "43px", marginTop: "20px" }}>
                        <div className="text-start">
                            <small style={{ color: "white" }}>{currentDate}</small>
                            <div
                                style={{
                                    bottom: "0",
                                    position: "absolute",
                                    marginBottom: "50px",
                                    color: "white",
                                }}
                            >
                                <h4>Welcome Back {user_name}</h4>
                                <small>Always stay updated with the Leadsense portal</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="enq">
                    <div className="d-flex">
                        {/* Approved Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Number of Leads</h3>
                            {loading ? (
                                <h2>Loading...</h2>
                            ) : (
                                <h2 className="h2tag">{totalLeads || "0"}</h2>
                            )}
                        </div>

                        {/* Prospect Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Rejected leads</h3>
                            {loading ? (
                                <h2>Loading...</h2>
                            ) : (
                                <h2 className="h2tag">{prospect || "0"}</h2>
                            )}
                        </div>

                        {/* Good Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Good Leads</h3>
                            {loading ? (
                                <h2>Loading...</h2>
                            ) : (
                                <h2 className="h2taginr">{goodLead || "0"}</h2>
                            )}
                        </div>

                        {/* Total Lead Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", padding: "20px" }}
                        >
                            <h3>Total Lead</h3>
                            {loading ? (
                                <h2>Loading...</h2>
                            ) : (
                                <h2 className="h2taginr">{totalLeads || "0"}</h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
